import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { User, ContactRequest } from '@pulpo/pulpo-models';
import { APP_CONFIG } from '@pulpo/app-config';
import ApiService from './api.service.abstract';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiService<User> {
  usersChangedSubject: Subject<User[]> = new Subject<User[]>();

  constructor(@Inject(APP_CONFIG) environment: any, private http: HttpClient) {
    super(environment, http, 'api/users');
  }

  listByCompany(): Observable<HttpResponse<User[]>> {
    return this.http.get<User[]>(`${this.url}/${this.endpoint}/company`, {
      observe: 'response',
    });
  }

  findTempById(id: number): Observable<HttpResponse<User>> {
    return this.http.get<User>(`${this.url}/${this.endpoint}/temp/${id}`, {
      observe: 'response',
    });
  }

  activate(key: string): Observable<HttpResponse<User>> {
    return this.http.get<User>(
      `${this.url}/${this.endpoint}/activate?key=${key}`,
      {
        observe: 'response',
      }
    );
  }

  sendContactRequest(request: ContactRequest) {
    return this.http.post<ContactRequest>(
      `${this.url}/${this.endpoint}` + '/contact',
      request,
      { observe: 'response' }
    );
  }

  resetPasswordInit(mail: string) {
    return this.http.post<User>(
      `${this.url}/${this.endpoint}` + '/reset-password/init/legacy',
      mail,
      { observe: 'response' }
    );
  }

  resetPasswordFinish(keyAndPassword: { key: string; newPassword: string }) {
    return this.http.post<User>(
      `${this.url}/${this.endpoint}` + '/reset-password/finish',
      keyAndPassword,
      { observe: 'response' }
    );
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.http.post<User>(
      `${this.url}/${this.endpoint}` + '/change-password',
      {
        currentPassword,
        newPassword,
      }
    );
  }

  enroll(mail: number): Observable<HttpResponse<User>> {
    return this.http.get<User>(
      `${this.url}/${this.endpoint}/enroll?mail=${mail}`,
      {
        observe: 'response',
      }
    );
  }

  unroll(userId: number): Observable<HttpResponse<User>> {
    return this.http.get<User>(
      `${this.url}/${this.endpoint}/unroll?userId=${userId}`,
      {
        observe: 'response',
      }
    );
  }
}
