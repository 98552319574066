<div *ngIf="dayReport" class="d-flex flex-column w-100 justify-content-center align-items-center">
    <span class="w-100 h-20 px-2 bg-blue mb-1">{{dayReport.date | date: 'dd/MM/YYYY'}}</span>
    <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
        <span class="w-33 px-2 bg-green text-center">OK</span>
        <span class="w-33 px-2 bg-orange text-center">KO</span>
        <span class="w-33 px-2 bg-orange-soft text-center">%</span>
    </div>

    <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
        <span class="w-33 px-2 bg-green-soft text-center text-dark">{{dayReport.availabilityOK}}</span>
        <span class="w-33 px-2 bg-orange-soft text-center text-dark">{{dayReport.availabilityKO}}</span>
        <span class="w-33 px-2 background-color text-center text-white">{{dayReport.ratioAvailabilityKO}}</span>
    </div>
    <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
        <span class="w-33 px-2 bg-green-soft text-center text-dark">{{dayReport.quotedOK}}</span>
        <span class="w-33 px-2 bg-orange-soft text-center text-dark">{{dayReport.quotedKO}}</span>
        <span class="w-33 px-2 background-color text-center text-white">{{dayReport.ratioQuotedKO}}</span>
    </div>
    <div class="w-100 h-20 d-flex flex-row justify-content-center align-items-center">
        <span class="w-33 px-2 bg-green-soft text-center text-dark">{{dayReport.bookedOK}}</span>
        <span class="w-33 px-2 bg-orange-soft text-center text-dark">{{dayReport.bookedKO}}</span>
        <span class="w-33 px-2 background-color text-center text-white">{{dayReport.ratioBookedKO}}</span>
    </div>
</div>
